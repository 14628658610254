import { AxiosInstance } from 'axios';
import {
  ICourse,
  IExamInfo,
  IFeedback,
  IGetCourseListParams,
  IGetCourseListResponse,
  ILeaveTeacherDurationPayload,
  ILeaveTeacherPayload,
  IPostCourseAcceptPayload,
  ISeriesBookParams,
  IGetSeriesBookResponse,
  IOrganizationPayload,
  IOrganization,
  IGroupPayload,
  IGroup,
  TUserCourseTagsResponse,
  IGetUserCourseTagsParams,
  IGetCourseTagParams,
  IGetCourseTagResponse,
  IAssignOtherActivityPayload,
  IAssignOtherActivityResponse,
  ITrackingAssignActivityResponse,
} from 'types/course';
import { TApiResponse } from 'types/common';
import {
  IClaimMessageParams,
  IClaimMessageResponse,
} from 'src/types/course/claimMessage.type';
import {
  CreateLeaveOrderPayload,
  CreateLeaveOrderResponse,
  GetLeaveOrderListParams,
  GetLeaveOrderListResponse,
} from 'types/leave';

import {
  GetSalaryListParams,
  GetSalaryListResponse,
  GetSalaryDetailResponse,
  GetSalaryMonthListParams,
  GetSalaryMonthListResponse,
  GetSalaryTotalByTeacherIdRequest,
  GetSalaryTotalByTeacherIdResponse,
} from 'types/compensationConfirmation';
import {
  IGetInteractiveLearningsRequest,
  IGetInteractiveLearningsResponse,
} from 'types/interactiveLearning';

export const oneClubGatewayApi = (
  axios: AxiosInstance,
  oneClubGateway: string,
) => ({
  /**
   * Use this one to check customer
   */
  checkIsACustomer(): TApiResponse<any> {
    return axios.get(`${oneClubGateway}/member/me`);
  },

  /**
   * Ask the teacher if he receives the order
   */
  postCourseAccept(
    id: string,
    payload: IPostCourseAcceptPayload,
  ): TApiResponse<ICourse> {
    return axios.post(
      `${oneClubGateway}/teacherCourse/${id}/isAccept`,
      payload,
    );
  },

  /**
   * Ask the teacher if he/she receives the batch scheduled course request
   */
  postBatchCourseAccept(
    id: string,
    payload: IPostCourseAcceptPayload,
  ): TApiResponse<ICourse> {
    return axios.post(
      `${oneClubGateway}/teacherCourse/batchCourse/${id}/isAccept`,
      payload,
    );
  },

  getUserCourseTags(
    teacherId: string,
    payload: IGetUserCourseTagsParams,
  ): TApiResponse<TUserCourseTagsResponse> {
    return axios.post(
      `${oneClubGateway}/external/teacher-tag/${teacherId}/courseTags`,
      payload,
    );
  },

  /**
   * Query course list
   */
  getCourseList(
    teacherId: string,
    params: IGetCourseListParams,
  ): TApiResponse<IGetCourseListResponse> {
    return axios.get(`${oneClubGateway}/teacherCourse/${teacherId}/list`, {
      params,
    });
  },
  /**
   * Query course
   */
  getCourse(courseId: string, teacherId: string): TApiResponse<ICourse> {
    return axios.get(
      `${oneClubGateway}/teacherCourse/${courseId}/${teacherId}`,
    );
  },
  /**
   * Query course test information
   */
  getCourseExams(courseId: string): TApiResponse<IExamInfo[]> {
    return axios.get(`${oneClubGateway}/teacherCourse/${courseId}/exams`);
  },

  /** 取得搶課單請求列表 */
  getTeacherClaimMessage(
    params?: IClaimMessageParams,
  ): TApiResponse<IClaimMessageResponse> {
    return axios.get(`${oneClubGateway}/teacherCourse/claim/message`, {
      params,
    });
  },

  /** 接受搶課單請求  */
  acceptClaimMessage(messageId: string): TApiResponse<boolean> {
    return axios.post(
      `${oneClubGateway}/teacherCourse/claim/message/${messageId}/accept`,
    );
  },

  /** 對課程指派活動-類型<其他>(teacher用)  */
  assignOtherActivity(
    courseId: string,
    payload: IAssignOtherActivityPayload,
  ): TApiResponse<IAssignOtherActivityResponse> {
    return axios.post(
      `${oneClubGateway}/teacherCourse/${courseId}/assignActivity/other`,
      payload,
    );
  },

  /** 追蹤指派活動建置進度(teacher用)  */
  trackingAssignActivity(
    trackingNumber: string,
  ): TApiResponse<ITrackingAssignActivityResponse> {
    return axios.get(
      `${oneClubGateway}/teacherCourse/assignActivity/tracking/${trackingNumber}`,
    );
  },

  /**
   * Teacher for leave
   */
  leaveTeacher(payload: ILeaveTeacherPayload): TApiResponse<any> {
    return axios.post(`${oneClubGateway}/leave/teacher`, payload);
  },

  /** too late for leave time limit */
  earlyLeaveTime(): TApiResponse<{
    earlyLeaveTime: number;
  }> {
    return axios.get(`${oneClubGateway}/leave/earlyLeaveTime`);
  },
  /**
   * Teacher asked for leave all day long
   */
  leaveTeacherDuration(
    payload: ILeaveTeacherDurationPayload,
  ): TApiResponse<any> {
    return axios.post(`${oneClubGateway}/leave/teacher/duration`, payload);
  },
  /**
   * Establish feedback
   */
  createFeedback(payload: IFeedback): TApiResponse<IFeedback> {
    return axios.post(`${oneClubGateway}/feedback-teacher`, payload);
  },
  /**
   * Get the feedback
   */
  getFeedback(teacherId: string, courseId: string): TApiResponse<IFeedback> {
    return axios.get(
      `${oneClubGateway}/feedback-teacher/${teacherId}/${courseId}`,
    );
  },

  getSeriesBooks(
    params: ISeriesBookParams,
  ): TApiResponse<IGetSeriesBookResponse> {
    return axios.get(`${oneClubGateway}/external/tag/resource`, { params });
  },

  /** 拿機構 id -> 機構資訊 */
  getOrganizationNames(
    payload: IOrganizationPayload,
  ): TApiResponse<IOrganization[]> {
    return axios.get(
      `${oneClubGateway}/external/course/link-tutorial-school/organizations`,
      {
        params: payload,
      },
    );
  },

  /** 拿班級 id -> 班級資訊 */
  getGroupNames(payload: IGroupPayload): TApiResponse<IGroup[]> {
    return axios.get(
      `${oneClubGateway}/external/course/link-tutorial-school/groups`,
      {
        params: payload,
      },
    );
  },

  /** get course tags */
  getCourseTags(
    params: IGetCourseTagParams,
  ): TApiResponse<IGetCourseTagResponse> {
    return axios.get(`${oneClubGateway}/tag/course`, { params });
  },

  /** create請假 */
  async createLeaveOrder(
    payload: CreateLeaveOrderPayload,
  ): TApiResponse<CreateLeaveOrderResponse> {
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    // use Object.entries instead of Object.keys
    Object.entries(payload).forEach(([key, value]) => {
      // if value belong primitive type, append it directly
      if (typeof value !== 'object') {
        formData.append(key, value);
        return;
      }
      // if value is object, stringify it first
      formData.append(key, JSON.stringify(value));
    });
    return await axios.post(
      `${oneClubGateway}/external/leave-order/create-leave-order`,
      formData,
      headers,
    );
  },
  /** get 請假列表 */
  getLeaveOrderList(
    params: GetLeaveOrderListParams,
  ): TApiResponse<GetLeaveOrderListResponse> {
    return axios.get(`${oneClubGateway}/external/leave-order`, { params });
  },

  /** get salary month list */
  getSalaryMonthList(
    params: GetSalaryMonthListParams,
  ): TApiResponse<GetSalaryMonthListResponse> {
    return axios.get(
      `${oneClubGateway}/external/salary/get-salary-month-list`,
      {
        params,
      },
    );
  },

  /** get salary list */
  getSalaryList(
    params: GetSalaryListParams,
  ): TApiResponse<GetSalaryListResponse> {
    return axios.get(`${oneClubGateway}/external/salary/get-salary-list`, {
      params,
    });
  },

  /** get salary detail */
  getSalaryDetailById(salaryId: string): TApiResponse<GetSalaryDetailResponse> {
    return axios.get(
      `${oneClubGateway}/external/salary/get-salary-detail/${salaryId}`,
    );
  },

  /** get the total monthly salary for a given teacher. */
  getSalaryTotalByTeacherId(
    params: GetSalaryTotalByTeacherIdRequest,
  ): TApiResponse<GetSalaryTotalByTeacherIdResponse> {
    return axios.get(`${oneClubGateway}/external/salary/get-salary-total`, {
      params,
    });
  },

  /** 取得符合 SeriesCodes 之遊戲資料（互動教材） */
  getInteractiveLearnings(
    params: IGetInteractiveLearningsRequest,
  ): TApiResponse<IGetInteractiveLearningsResponse> {
    return axios.get(`${oneClubGateway}/interactive-learning`, { params });
  },
});
