import { AxiosInstance } from 'axios';
import {
  IOtherActivityByCourseUidResponse,
  IOtherResourceByActivityIdPayload,
  IOtherResourceByActivityIdResponse,
  IOtherStudentStatusByActivityIdPayload,
  IOtherStudentStatusByActivityIdResponse,
} from 'types/oneAcademy/oneAcademy.type';
import { TLinkPlusApiResponse } from 'types/common';

export const oneAcademyApi = (axios: AxiosInstance, oneAcademyUrl: string) => ({
  // 查詢其他活動資源
  getOtherResourceByActivityId(
    payload: IOtherResourceByActivityIdPayload,
  ): TLinkPlusApiResponse<IOtherResourceByActivityIdResponse> {
    return axios.post(
      `${oneAcademyUrl}/mms/textbook/get/otherResource/byActivityId`,
      payload,
    );
  },
  // 用課程uid查詢其他活動資料(簡易資料) Allowed: All Users
  getOtherActivityByCourseUid(payload: {
    mmsCourseUid: string;
  }): TLinkPlusApiResponse<IOtherActivityByCourseUidResponse> {
    return axios.post(
      `${oneAcademyUrl}/mms/textbook/get/other/activity/byCourseUid`,
      payload,
    );
  },
  // 學生其他活動狀態資訊(單個學生狀態)
  getOtherStudentStatusByActivityId(
    payload: IOtherStudentStatusByActivityIdPayload,
  ): TLinkPlusApiResponse<IOtherStudentStatusByActivityIdResponse> {
    return axios.post(
      `${oneAcademyUrl}/mms/textbook/get/other/student/status/byActivityId`,
      payload,
    );
  },
  // 取得特定機構列表
  getOrganByOrgIds(payload: {
    orgUids: string[];
  }): TLinkPlusApiResponse<{ uid: string; name: string }[]> {
    return axios.post(`${oneAcademyUrl}/mms/get/organ/byOrgIds`, payload);
  },
});
