import {
  oneClubApi,
  teachersApi,
  oneClubGatewayApi,
  oneClubGatewayStaffMemberApi,
  coursesApi,
  boardApi,
  liveClassApi,
  teacherRecruitApi,
  referenceSystemApi,
  recommendationCodeApi,
  oneAnswerApi,
  oneAcademyApi,
} from 'api/index';
import instance from './instance';

export const URL = {
  ONECLUB: import.meta.env.VITE_ONECLUB_API,
  TEACHERS: import.meta.env.VITE_TEACHERS_API,
  TEACHERS_RECRUIT: import.meta.env.VITE_TEACHER_RECRUIT_API,
  COURSES: import.meta.env.VITE_COURSES_API,
  ONECLUB_GATEWAY: import.meta.env.VITE_ONECLUB_GATEWAY_API,
  ONECLUB_GATEWAY_STAFF_MEMBER: import.meta.env
    .VITE_ONECLUB_GATEWAY_API_STAFF_MEMBER,
  ONEBOARD: import.meta.env.VITE_ONEBOARD_API_DOMAIN,
  LIVE_CLASS: import.meta.env.VITE_LIVE_CLASS_API_DOMAIN,
  REFERENCE_SYSTEM: import.meta.env.VITE_REFERENCE_SYSTEM,
  RECOMMENDATION_CODE: import.meta.env.VITE_CREATE_RECOMMENDATION_CODE,
  ONE_ANSWER: import.meta.env.VITE_ONEANSWER_API_DOMAIN,
  HELPER_MEMBER_DEV: import.meta.env.VITE_HELPER_MEMBER_DEV,
  ONE_ACADEMY: import.meta.env.VITE_ONEACADEMY_API_DOMAIN,
};

export default {
  oneClub: oneClubApi(instance, `${URL.ONECLUB}/dev`),
  teachers: teachersApi(instance, `${URL.TEACHERS}/api`),
  teacherRecruit: teacherRecruitApi(instance, `${URL.TEACHERS_RECRUIT}`),
  courses: coursesApi(instance, `${URL.COURSES}/api`),
  oneClubGateway: oneClubGatewayApi(instance, `${URL.ONECLUB_GATEWAY}`),
  oneClubGatewayStaffMember: oneClubGatewayStaffMemberApi(
    instance,
    `${URL.ONECLUB_GATEWAY_STAFF_MEMBER}`,
  ),
  board: boardApi(instance, `${URL.ONEBOARD}`),
  liveClass: liveClassApi(instance, `${URL.LIVE_CLASS}`),
  referenceSystem: referenceSystemApi(instance, `${URL.REFERENCE_SYSTEM}`),
  recommendationCode: recommendationCodeApi(
    instance,
    `${URL.RECOMMENDATION_CODE}`,
  ),
  oneAnswer: oneAnswerApi(instance, `${URL.ONE_ANSWER}`),
  oneAcademy: oneAcademyApi(instance, `${URL.ONE_ACADEMY}`),
};
